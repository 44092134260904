@import "../../Root/common.scss";

.smallButton {
  width: 160px;
  height: 40px;
  padding: 5px 10px;
  background-color: black;
  border: 1px solid $fifth-color;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: white;
    color: black;
    font-weight: 700;
  }
}
