@import "../../Root/common.scss";

.wrapper {
  width: 80%;
  min-height: 75vh;
  margin: 0 auto;
  .searchedInformations {
    margin: 20px auto;
    .message {
      color: red;
      font-weight: 700;
      text-align: center;
    }
  }
  .informations {
    margin-top: 40px;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    .search {
      form {
        display: flex;
        align-items: center;
      }
    }
  }
}
