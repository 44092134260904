@import "../../Root/common.scss";

.wrapper {
  margin: 20px auto;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    p {
      font-weight: 700;
      span {
        color: red;
      }
    }
    button {
      width: 80px;
      height: 30px;
      font-size: 14px;
    }
  }
  p {
    margin-bottom: 10px;
  }
  button {
    width: 80px;
    height: 30px;
    font-size: 14px;
  }
  ul {
    padding-left: 30px;
    list-style: none;
  }
}
