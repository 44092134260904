@import "../../Root/common.scss";

.wrapper {
  position: relative;
  width: 70vw;
  height: 70vh;

  h3 {
    margin-bottom: 20px;
    text-align: center;
  }

  .form {
    display: flex;
    flex-direction: column;

    .input {
      margin: 20px auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      div {
        flex-basis: 30%;

        .name {
          width: 220px;
          height: 20px;
          border: 1px solid blue;
        }
      }

      label {
        margin-right: 20px;
      }
    }

    .notes {
      div {
        display: flex;
        flex-direction: column;

        textarea {
          height: 250px;
        }
      }
    }

    .buttons {
      position: absolute;
      bottom: 10px;
      left: 30px;
      right: 30px;

      display: flex;
      justify-content: space-around;
      margin: 20px auto;
    }
  }
}