@import "../../Root/common.scss";

.wrapper {
  width: 75%;
  min-height: 70vh;
  margin: 20px auto;
  .buttons {
    display: flex;
    justify-content: space-around;
  }
}
