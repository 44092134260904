@import "../../Root/common.scss";

.wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid black;
  padding-top: 10px;
}
