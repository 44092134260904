@import "../../Root/common.scss";

.backButton {
  padding: 5px 10px;
  background-color: $fifth-color;
  color: white;
  border: 1px solid red;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: black;
  }
}
