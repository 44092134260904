@import "../../Root/common.scss";

.wrapper {
  .inside {
    width: 250px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    h4 {
      flex-basis: 90%;
      margin: 20px auto;
      text-align: center;
      color: red;
    }
    button {
      font-size: 14px;
      width: 100px;
      height: 30px;
    }
  }
}
