@import "../../Root/common.scss";
.wrapper {
  width: 70%;
  margin: 40px auto;
  padding-bottom: 20px;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .information {
      margin: 20px auto;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .login,
      .password,
      .button {
        margin-top: 20px;
      }
    }
  }
}
.validateMessage {
  color: red;
  font-weight: 700;
}
